import React from "react";
import { Styling } from "./styled";
import TextBlock from "./TextBlock";
import ImageBlock from "./ImageBlock";
import VideoBlock from "./VideoBlock";
import AudioPlayer from "../AudioPlayer";
import Quote from "./Quote";
import SnipcartButton from "./SnipcartButton";

export default function FlexibleContent({ flexible, slug }) {
  const content = flexible.flexibleContent.postBuilder;

  return (
    <Styling className="flexible-content-wrapper">
      <div className="flexible-content-inner">
        {content.map((flexItem, index) => {
          let type = flexItem.fieldGroupName;

          //    //console.log(type)

          if (type.includes("_TextBlock")) {
            return <TextBlock key={`${type}_${index}`} data={flexItem} />;
          }
          if (type.includes("_VideoBlock")) {
            return <VideoBlock key={`${type}_${index}`} data={flexItem} />;
          }
          if (type.includes("_ImageBlock")) {
            return <ImageBlock key={`${type}_${index}`} data={flexItem} />;
          }
          if (type.includes("_AudioPlayer")) {
            return <AudioPlayer key={`${type}_${index}`} audioFile={flexItem} />;
          }
          if (type.includes("_SnipcartButton")) {
            return <SnipcartButton key={`${type}_${index}`} data={flexItem} slug={slug} />;
          }

          if (type.includes("_Quote")) {
            return <Quote key={`${type}_${index}`} data={flexItem} />;
          }
        })}
      </div>
    </Styling>
  );
}
