import styled from 'styled-components'

const Styling = styled.div`
width: 100%;
background:var(--white);

    .scroll-content{
    width: 100%;
    margin: 0 0 0 auto;
    
    @media only screen and (min-width: 850px) {
    padding:16rem 0 0 0;
    width: 50%;
    }

        .flexible-content-wrapper{
        padding-bottom: 0;
        }



        .mobileImg{
        display: block;
        width:100%;
        height:auto;
        max-height: 50vh;

            @media only screen and (min-width: 850px) {
            display: none;
            }

            &.topLeft{
                img{
                    object-position: 0 0;
                } 
            }

            &.topCenter{
                img{
                object-position: 50% 0;
                }
            }

            &.topRight{
                img{
                object-position: 100% 0;
                }
            }

            &.middleLeft{
                img{
                object-position: 0 50%;
                }
            }

            &.middleCenter{
                img{
                object-position: 50% 50%;
                }
            }

            &.middleRight{
                img{
                object-position: 100% 50% ;
                }
            }

            &.bottomLeft{
                img{
                object-position: 0 100%;
                }
            }

            &.bottomCenter{
                img{
                object-position: 50% 100%;
                }
            }
            
            &.bottomRight{
                img{
                object-position: 100% 100%;
                }
            }
        }

        .snipcart-add-item{
        margin-bottom: 8rem;
        margin-left:4rem;

            @media only screen and (min-width: 1024px) {
                margin-left:8rem;
            }

        }
        .testiIntro{
        background:var(--white); 
        position: relative;
        padding:4rem 0 0 0;
        width: 100%;
        

        }
        .main-title,
        .course{
        width: 100%;
        position: relative;
        padding: 0 2rem;

            @media only screen and (min-width: 350px) {
            padding: 0 4rem;
            }

            @media only screen and (min-width: 1024px) {
                padding: 0 8rem;
            }
        }

        .main-title{
        text-transform: uppercase;
        ${({theme}) => theme.tomato('regular')}  
        font-size:3rem;
        line-height:2.6rem;
        letter-spacing: -0.2rem;

            @media only screen and (min-width: 420px) {
            font-size:4rem;
            line-height:3rem;
            letter-spacing: -0.3rem;
            }
            
            @media only screen and (min-width: 610px) {
            font-size:5rem;
            line-height:4rem;
            letter-spacing: -0.4rem;
            }
            
            @media only screen and (min-width: 800px) {
            font-size:6rem;
            line-height:5rem;
            }


            @media only screen and (min-width: 850px) {
            margin-bottom: 2rem;
            }

        }

        .flexible-content-wrapper{
        margin:0;
        padding-top:4rem;
        position: relative;
        width: 100%;
        }

        .course{
        font-size: clamp(2rem, 1.8vw, 4rem);
        text-transform: uppercase;
        }

        /* .flexible-content-inner{
            *:last-child{
            margin-bottom:0;
            }
        } */

        .mastering-form-wrapper{
        width:100%;
        position:relative;
        padding: 4rem;
        overflow:hidden;
        }

        .cta{
        width: 100%;
        position: relative;
        padding:0 1rem 4rem 1rem;
        background:var(--white);

            @media only screen and (min-width: 350px) {
            padding:0 4rem 4rem 4rem;
            }

            @media only screen and (min-width: 1024px) {
            padding:0 8rem 8rem 8rem;
            
            }
        
        }
        .cta-inner{
        padding: 4rem;
        position:relative;
        box-shadow: 0px -30px 80px rgba(0, 0, 0, 0.07), 0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0531481), 0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0425185), 0px 20px 13px rgba(0, 0, 0, 0.035), 0px 8.14815px 6.51852px rgba(0, 0, 0, 0.0274815), 0px 1.85185px 3.14815px rgba(0, 0, 0, 0.0168519);
        border-radius: 8px; 

            @media only screen and (min-width: 1024px) {
            box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0531481), 0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0425185), 0px 20px 13px rgba(0, 0, 0, 0.035), 0px 8.14815px 6.51852px rgba(0, 0, 0, 0.0274815), 0px 1.85185px 3.14815px rgba(0, 0, 0, 0.0168519);
            }
        

       
            .title{
            font-size: clamp(2rem, 2vw, 5rem);
            text-transform: uppercase;
            letter-spacing: -0.06em;
            ${({theme}) => theme.tomato('regular')}  
            margin-bottom:2rem;
            }    

            .text{
            margin-bottom: 2rem;
            font-size:1.4rem;
            line-height:2rem;
            }
        
        }
    }
`

export { Styling }