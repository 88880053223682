import React from "react";
import { Styling } from "./styled.js";

export default function SnipcartButton({ data, slug }) {
  const slugify = (string) => {
    return string.toLowerCase().replace(/ /g, "-");
  };

  const id = slugify(data.productName);

  return (
    <Styling className="flex snipcartButton">
      <button className="snipcart-add-item capsule_button black" data-item-id={id} data-item-price={data.price} data-item-url={`/${slug}`} data-item-description={data.productDescription} data-item-name={data.productName} data-item-shippable={false}>
        Add to cart
      </button>
    </Styling>
  );
}
