import styled from "styled-components";

const Styling = styled.div`
  font-size: 1.6rem;
  line-height: 2.5rem;
  margin-bottom: 4rem;
  padding: 0 4rem;

  @media only screen and (min-width: 1024px) {
    margin-bottom: 8rem;
    padding: 0 8rem;
  }

  &.text-block:has(+ .snipcartButton) {
    margin-bottom: 4rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 2rem;
    line-height: 1em;
    text-transform: uppercase;
  }

  h1 {
    font-size: 3em;
  }
  h2 {
    font-size: 2.5em;
  }
  h3 {
    font-size: 2em;
  }
  h4 {
    font-size: 1.8em;
  }
  h5 {
    font-size: 1.6em;
  }
  h6 {
    font-size: 1.3em;
  }

  strong {
    font-weight: bold;
    /* font-family: 'tomato--bold'; */
  }

  p {
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    width: 100%;
    position: relative;
    margin-bottom: 2rem;

    li {
      position: relative;
      margin-bottom: 1rem;
      padding-left: 1.5rem;

      &:before {
        content: "";
        width: 0.7rem;
        height: 0.7rem;
        position: absolute;
        left: 0;
        top: 9px;
        margin-right: 1rem;
        background: black;
        display: inline-block;
        border-radius: 100%;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export { Styling };
