import styled from "styled-components";

const Styling = styled.div`
  font-size: 1.6rem;
  line-height: 2.5rem;
  margin-bottom: 4rem;
  padding: 0 8rem;
`;

export { Styling };
